
import { defineComponent, ref, watch } from "vue";
import axios from "@/api/axios";
import UiInput from "@/components/UI/Input";
import UiSelect from "@/components/UI/Select";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import { useRouter, useRoute } from "vue-router";

export interface Iaddress {
  receiver: string;
  contactTel: string;
  address: string;
  receiveAddress: string;
  isDefault: boolean;
  province: string;
  country: string;
  city: string;
}
export default defineComponent({
  name: "addAddress",
  components: {
    UiInput,
    UiSelect,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.query.id || "";

    const address = ref<Iaddress>({
      receiver: "",
      contactTel: "",
      address: "",
      receiveAddress: "",
      isDefault: false,
      province: "",
      country: "",
      city: "",
    });
    if (id) {
      axios
        .get("/M/User/AddAddress/" + id)
        .then((res) => {
          const data = res.data.obj.address;
          const province = data.province || "";
          const city = data.city || "";
          const country = data.country || "";
          data.isDefault == 1?data.isDefault = true:data.isDefault=false
          address.value = { ...data, address: province + " " + city + " " + country };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function back() {
      router.go(-1);
    }

    function save() {
      
     
      const data = qs.stringify({
        id,
        receiver: address.value.receiver,
        contactTel: address.value.contactTel,
        receiveAddress: address.value.receiveAddress,
        isdefault: address.value.isDefault ? "1" : "0",
        province: (address.value.address && address.value.address.split(" ")[0]) || "",
        country: (address.value.address && address.value.address.split(" ")[2]) || "",
        city: (address.value.address && address.value.address.split(" ")[1]) || "",
      });
      console.log(address.value);
      axios
        .post("/M/Server/DoSaveAddress", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                back();
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      address,
      save,
    };
  },
});
